import React from "react";
import City from "../models/City";

export default function Region({cities, regionName, picture}: {cities: City[], regionName: string, picture: string}) {
    const days: number = cities ? Math.min(...cities.map(city => city.days_without_rain)) : 1;
    return (
        <div className="grid-item" style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${picture})`,
            borderBottom: `0.5px solid rgba(255, 255, 255, 0.7)`,
        }} >
            <div className="content-wrapper">
                {/*<img src={picture} alt={regionName}/>*/}
                <h1>{regionName}</h1>
                <p>{days}</p>
            </div>

        </div>
    );
}