import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './App.css';
import City from "../models/City";
import Region from "./Region"; // Import CSS file for styling
import {Constants} from "./Constants";

function ComponentOne({days}: { days: number }) {
    return (
        <div className="component-one">
            <div className="grid-item-main">
                <div className="content-wrapper">
                    <p>{days}</p>
                </div>
            </div>
        </div>
    );
}

function ComponentTwo({citiesByRegion}: { citiesByRegion: { [key: string]: City[] } }) {
    return (
        <div className="component-two">
            <Region cities={citiesByRegion[Constants.badenWuertemberg]}
                    regionName={Constants.badenWuertemberg}
                    picture={Constants.badenWuertembergPicture}/>
            <Region cities={citiesByRegion[Constants.bayern]}
                    regionName={Constants.bayern}
                    picture={Constants.bayernPicture}/>
            <Region cities={citiesByRegion[Constants.berlin]}
                    regionName={Constants.berlin}
                    picture={Constants.berlinPicture}/>
            <Region cities={citiesByRegion[Constants.brandenburg]}
                    regionName={Constants.brandenburg}
                    picture={Constants.brandenburgPicture}/>
            <Region cities={citiesByRegion[Constants.bremen]}
                    regionName={Constants.bremen}
                    picture={Constants.bremenPicture}/>
            <Region cities={citiesByRegion[Constants.hamburg]}
                    regionName={Constants.hamburg}
                    picture={Constants.hamburgPicture}/>
            <Region cities={citiesByRegion[Constants.hessen]}
                    regionName={Constants.hessen}
                    picture={Constants.hessenPicture}/>
            <Region cities={citiesByRegion[Constants.mecklenburgVorpommern]}
                    regionName={Constants.mecklenburgVorpommern}
                    picture={Constants.mecklenburgVorpommernPicture}/>
            <Region cities={citiesByRegion[Constants.niedersachsen]}
                    regionName={Constants.niedersachsen}
                    picture={Constants.niedersachsenPicture}/>
            <Region cities={citiesByRegion[Constants.nordrheinWestfalen]}
                    regionName={Constants.nordrheinWestfalen}
                    picture={Constants.nordrheinWestfalenPicture}/>
            <Region cities={citiesByRegion[Constants.rheinlandPfalz]}
                    regionName={Constants.rheinlandPfalz}
                    picture={Constants.rheinlandPfalzPicture}/>
            <Region cities={citiesByRegion[Constants.saarland]}
                    regionName={Constants.saarland}
                    picture={Constants.saarlandPicture}/>
            <Region cities={citiesByRegion[Constants.sachsen]}
                    regionName={Constants.sachsen}
                    picture={Constants.sachsenPicture}/>
            <Region cities={citiesByRegion[Constants.sachsenAnhalt]}
                    regionName={Constants.sachsenAnhalt}
                    picture={Constants.sachsenAnhaltPicture}/>
            <Region cities={citiesByRegion[Constants.schleswigHolstein]}
                    regionName={Constants.schleswigHolstein}
                    picture={Constants.schleswigHolsteinPicture}/>
            <Region cities={citiesByRegion[Constants.thueringen]}
                    regionName={Constants.thueringen}
                    picture={Constants.thueringenPicture}/>
        </div>
    );
}

function App() {
    // Execute a GET rest api request to a server and store the received data in a list
    const [citiesByRegion, setCitiesByRegion] = useState<{ [key: string]: City[] }>({});
    const [smallestDayWithoutRain, setSmallestDayWithoutRain] = useState<number>(0);
    const fetchCities = async () => {
        try {
            const response = await axios.get('https://dayssincethelastrainingermany.com:5002/RainyDay');
            const cities: City[] = await response.data;

            const smallestDays = Math.min(...cities.map(city => city.days_without_rain));
            setSmallestDayWithoutRain(smallestDays);

            const citiesGroupedByRegion: { [key: string]: City[] } = {};
            cities.forEach(city => {
                if (!citiesGroupedByRegion[city.region]) {
                    citiesGroupedByRegion[city.region] = [];
                }
                citiesGroupedByRegion[city.region].push(city);
            });
            setCitiesByRegion(citiesGroupedByRegion);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCities();
    }, []);

    return (
        <div className="app-container">
            <ComponentOne days={smallestDayWithoutRain}/>
            <ComponentTwo citiesByRegion={citiesByRegion}/>
        </div>
    );
}

export default App;