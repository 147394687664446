export class Constants{
    static readonly badenWuertemberg : string = "Baden-Württemberg";
    static readonly bayern: string = "Bayern";
    static readonly berlin: string = "Berlin";
    static readonly brandenburg: string = "Brandenburg";
    static readonly bremen: string = "Bremen";
    static readonly hamburg: string = "Hamburg";
    static readonly hessen: string = "Hessen";
    static readonly mecklenburgVorpommern: string = "Mecklenburg-Vorpommern";
    static readonly niedersachsen: string = "Niedersachsen";
    static readonly nordrheinWestfalen: string = "Nordrhein-Westfalen";
    static readonly rheinlandPfalz: string = "Rheinland-Pfalz";
    static readonly saarland: string = "Saarland";
    static readonly sachsen: string = "Sachsen";
    static readonly sachsenAnhalt: string = "Sachsen-Anhalt";
    static readonly schleswigHolstein: string = "Schleswig-Holstein";
    static readonly thueringen: string = "Thüringen";

    static readonly badenWuertembergPicture : string = "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Baden-Württemberg_%28state%2C_greater_arms%29_2020.svg";
    static readonly bayernPicture: string = "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_Bavaria_%28lozengy%29.svg";
    static readonly berlinPicture: string = "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Berlin_%28state%29.svg";
    static readonly brandenburgPicture: string = "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Brandenburg.svg";
    static readonly bremenPicture: string = "https://upload.wikimedia.org/wikipedia/commons/5/5a/State_flag_of_Bremen.svg";
    static readonly hamburgPicture: string = "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flagge_Hamburg.svg";
    static readonly hessenPicture: string = "https://upload.wikimedia.org/wikipedia/commons/6/6c/Flag_of_Hesse_%28state%29.svg";
    static readonly mecklenburgVorpommernPicture: string = "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Mecklenburg-Western_Pomerania_%28state%29.svg";
    static readonly niedersachsenPicture: string = "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Lower_Saxony_%28state_ensign%29.svg";
    static readonly nordrheinWestfalenPicture: string = "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_North_Rhine-Westphalia_%28state%29.svg";
    static readonly rheinlandPfalzPicture: string = "https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Rhineland-Palatinate.svg";
    static readonly saarlandPicture: string = "https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Saarland.svg";
    static readonly sachsenPicture: string = "https://upload.wikimedia.org/wikipedia/commons/f/f1/Flag_of_Saxony_%28state%29.svg";
    static readonly sachsenAnhaltPicture: string = "https://upload.wikimedia.org/wikipedia/commons/c/c2/Flag_of_Saxony-Anhalt_%28state%29.svg";
    static readonly schleswigHolsteinPicture: string = "https://upload.wikimedia.org/wikipedia/commons/c/c8/Flag_of_Schleswig-Holstein_%28state%29.svg";
    static readonly thueringenPicture: string = "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Thuringia_%28state%29.svg";
}